//
// window.addEventListener('mouseup', function (e){
//     console.log(e.target.outerText)
//     var msg = new SpeechSynthesisUtterance();
//     msg.text = e.target.outerText;
//     window.speechSynthesis.speak(msg);
// })
// window.onselect = selectText;
//
// function selectText(e) {
//   let  start = e.target.selectionStart;
//   let   end = e.target.selectionEnd;
//     alert(e.target.value.substring(start, end));
// }
import state from '../../store/index'
function getSelectionText() {
    var text = "";
    var activeEl = document.activeElement;
    var activeElTagName = activeEl ? activeEl.tagName.toLowerCase() : null;
    if (
        (activeElTagName == "textarea") || (activeElTagName == "input" &&
            /^(?:text|search|password|tel|url)$/i.test(activeEl.type)) &&
        (typeof activeEl.selectionStart == "number")
    ) {
        text = activeEl.value.slice(activeEl.selectionStart, activeEl.selectionEnd);
    } else if (window.getSelection) {
        text = window.getSelection().toString();
    }
    return text;
}

document.onmouseup = document.onkeyup = document.onselectionchange = function() {

    state.state.quizText=getSelectionText()
    // let a=getSelectionText()
    // quizText=a
};