import Vue from 'vue'


import App from './App.vue'
import './registerServiceWorker'
import router from './router'

import store from './store'
import './assets/css/styles.css'
import './assets/css/bootstrap-grid.css'
import './assets/css/leaflet.css'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import axios from 'axios'
Vue.prototype.$http = axios;


axios.defaults.baseURL = "https://backend.madaniymeros.uz/v1"
// Make BootstrapVue available throughout your project

Vue.use(BootstrapVue)
import VueYoutube from 'vue-youtube'
Vue.use(VueYoutube)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
import './assets/js/index'
import './click'
import Toasted from 'vue-toasted';
Vue.use(Toasted)
Vue.config.productionTip = false
import Paginate from 'vuejs-paginate'
Vue.component('paginate', Paginate)
// import AOS from 'aos'
// import 'aos/dist/aos.css'
//language
router.beforeEach((to,from,next)=>{
  let language=to.query.lang
  if(language){
    localStorage.setItem('lang',language)
  }
  next()
})


new Vue({
  router,
  store,
  render: h => h(App),
// mounted() {
//     AOS.init()
// }
}).$mount('#app')
