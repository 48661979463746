<template>
  <div>
    <footer>
      <div class="images_bg">
        <div class="footer_top">
          <div class=" container top_footer">
            <div class="footer_header">
              <span>{{ $store.state.language.lang[0] }}</span>
            </div>
            <div class="row">
              <div class="col-xl-3 col-sm-6 col-12">
                <div class="footer_link_header">
                  <router-link to="/">{{ $store.state.language.lang[1] }}</router-link>
                </div>

                <div class="footer_link">
                  <router-link to="/agency/management">{{ $store.state.language.lang[10] }}</router-link>
                </div>
                <div class="footer_link">
                  <router-link to="/central/office">{{ $store.state.language.lang[9] }}</router-link>
                </div>

                <div class="footer_link">
                  <router-link to="/council">{{ $store.state.language.lang[12] }}</router-link>
                </div>


                <div class="footer_link">
                  <router-link to="/vacancy">{{ $store.state.language.lang[19] }}</router-link>
                </div>
                <div class="footer_link">
                  <router-link to="/">
                    <router-link to="/museum">{{ $store.state.language.lang[18] }}</router-link>
                  </router-link>
                </div>
                <div class="footer_link">
                  <router-link to="/activity">{{ $store.state.language.lang[17] }}</router-link>
                </div>
                <div class="footer_link">
                  <router-link to="/organization">{{ $store.state.language.lang[16] }}</router-link>
                </div>
                <div class="footer_link">
                  <router-link to="/regulations">{{ $store.state.language.lang[15] }}</router-link>
                </div>
                <div class="footer_link">
                  <router-link to="/territorial">{{ $store.state.language.lang[13] }}</router-link>
                </div>
              </div>
              <div class="col-xl-3 col-sm-6 col-12">
                <div class="footer_link_header">
                  <router-link to="/">
                    {{ $store.state.language.lang[3] }}
                  </router-link>
                </div>
                <div class="footer_link">
                  <router-link to="/media">{{ $store.state.language.lang[75] }}</router-link>
                </div>
                <div class="footer_link">
                  <router-link to="/tenders">{{ $store.state.language.lang[74] }}</router-link>
                </div>
                <div class="footer_link">
                  <router-link to="/press_releases">{{ $store.state.language.lang[72] }}</router-link>
                </div>
                <div class="footer_link">
                  <router-link to="/press_secretary">{{ $store.state.language.lang[71] }}</router-link>
                </div>
                <div class="footer_link">
                  <router-link to="/news">{{ $store.state.language.lang[69] }}</router-link>
                </div>
              </div>
              <div class="col-xl-3 col-sm-6 col-12">
                <div class="footer_link_header">
                  <router-link to="/contact">
                    {{ $store.state.language.lang[8] }}
                  </router-link>
                </div>
                <div class="footer_link_header">
                  <router-link to="/opendata">
                    {{ $store.state.language.lang[6] }}
                  </router-link>
                </div>
                <div class="footer_link_header">
                  <router-link to="/service">
                    {{ $store.state.language.lang[2] }}
                  </router-link>
                </div>
                <!-- START WWW.UZ TOP-RATING -->
                <SCRIPT language="javascript" type="text/javascript">
                  <!--
                  top_js = "1.0";
                  top_r = "id=36629&r=" + escape(document.referrer) + "&pg=" + escape(window.location.href);
                  document.cookie = "smart_top=1; path=/";
                  top_r += "&c=" + (document.cookie ? "Y" : "N")
                  //-->
                </SCRIPT>
                <SCRIPT language="javascript1.1" type="text/javascript">
                  <!--
                  top_js = "1.1";
                  top_r += "&j=" + (navigator.javaEnabled() ? "Y" : "N")
                  //-->
                </SCRIPT>
                <SCRIPT language="javascript1.2" type="text/javascript">
                  <!--
                  top_js = "1.2";
                  top_r += "&wh=" + screen.width + 'x' + screen.height + "&px=" +
                      (((navigator.appName.substring(0, 3) == "Mic")) ? screen.colorDepth : screen.pixelDepth)
                  //-->
                </SCRIPT>
                <SCRIPT language="javascript1.3" type="text/javascript">
                  <!--
                  top_js = "1.3";
                  //-->
                </SCRIPT>
                <SCRIPT language="JavaScript" type="text/javascript">
                  <!--
                  top_rat = "&col=340F6E&t=ffffff&p=BD6F6F";
                  top_r += "&js=" + top_js + "";
                  document.write('<a href="http://www.uz/uz/res/visitor/index?id=36629" target=_top><img src="http://cnt0.www.uz/counter/collect?' + top_r + top_rat + '" width=88 height=31 border=0 alt="Топ рейтинг www.uz"></a>')//-->
                </SCRIPT>
                <a  href="http://www.uz/uz/res/visitor/index?id=36629" target=_blank>
                  <img height=31
                       src="http://cnt0.www.uz/counter/collect?id=36629&pg=http%3A//uzinfocom.uz&&col=340F6E&amp;t=ffffff&amp;p=BD6F6F"
                       width=88 border=0
                       alt="Топ рейтинг www.uz"></a>
                <NOSCRIPT>
                  <A href="http://www.uz/uz/res/visitor/index?id=36629" target=_top>
                  <img height=31 src="http://cnt0.www.uz/counter/collect?id=36629&pg=http%3A//madaniymeros.uz&&col=340F6E&amp;t=ffffff&amp;p=BD6F6F"
               width=88 border=0
               alt="Топ рейтинг www.uz"></A>
                </NOSCRIPT><!-- FINISH WWW.UZ TOP-RATING -->
              </div>
              <div class="col-xl-3 col-sm-6 col-12" v-if="$store.state.contact[0]">
                <div class="contact_footer">
                  <div class="phone_footer">
                    <div class="phone_footer_head">{{ $store.state.language.lang[56] }}</div>
                    <div class="phone_footer_body">{{ $store.state.contact[0].first_phone_number }}</div>
                    <div class="phone_footer_body">{{ $store.state.contact[0].second_phone_number }}</div>
                    <div class="phone_footer_body">{{ $store.state.contact[0].third_phone_number }}</div>
                  </div>
                  <div class="phone_footer">
                    <div class="phone_footer_head">{{ $store.state.language.lang[60] }}</div>
                    <div class="phone_footer_body">{{ $store.state.contact[0].email }}</div>
                  </div>
                  <div class="phone_footer">
                    <div class="phone_footer_head">{{ $store.state.language.lang[79] }}</div>
                    <div class="phone_footer_body">{{ $store.state.contact[0].working_days }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer_bottom">
          <div class="container">
            <p> {{ $store.state.language.lang[48] }}</p>
          </div>
          <div class="container mb-120">
            <p>

            <a rel="license" href="http://creativecommons.org/licenses/by/4.0/">
              <img alt="Creative Commons Attribution 4.0 International" style="border:stx;" src="https://i.creativecommons.org/l/by/4.0/80x15.png" />
              </a><br />
              {{ $store.state.language.lang[189] }}
              <a rel="license" href="http://creativecommons.org/licenses/by/4.0/">Creative 
              Commons Attribution 4.0 International</a>.
            </p>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  mounted() {
    this.$store.dispatch('contactFunck')
  }
}
</script>