import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
import router from "../router";
import uz from '../locales/uz.json'
import ru from '../locales/ru.json'
import uz_latn from '../locales/uz_latn.json'
import en from '../locales/en.json'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    quizText:'sa',
    media2:'',
    slider:[],
    news:[],
    museum:[],
    object:[],
    object1:[],
    stat:[],
    management:[],
    management2:[],
    management1:[],
    management3:[],
    transparencyid:[],
    docs1:[],
    structure:[],
    territorial:[],
    organization:[],
    museumm:[],
    managment_category:[],
    council_advice:[],
    vacancy:[],
    about:[],
    newsM:[],
    press_secretary:[],
    media:[],
    services:[],
    museumId:[],
    opendata:[],
    transparency:[],
    isLoading:false,
    refCount: 0,
    searchs:[],
    language:ru,
    newsMsingl:[],
    docsn1:[],
    docsn:[],
    docs3:[],
    docs4:[],
    mn:'',
    tre:'',
    heritage_category:[],
    heritage_main:[],
    museum_main: [],
    heritage_id:'',
    contact:'',
    path:'',
    docx:[],
    docxmain:[],
    docs15:[],
    docs16:[],
    docs17:[],
    docs18:[],
    docs19:[],
    docs20:[],
    docs21:[],
    docs22:[],
    docs23:[],
    docs24:[],
    journal:[],
    searchs_id:[],
    opendataid:[],
    images:[]
  },
  mutations: {
    loading(state, isLoading) {

      if (isLoading) {
        state.refCount++;
        state.isLoading = true;
      } else if (state.refCount > 0) {
        state.refCount--;
        state.isLoading = (state.refCount > 0);
      }
    },
  },
  actions: {
    change({state}){

      if(isNaN(localStorage.getItem('lang'))===false){
        state.language=uz
        document.title=state.language.lang[0]
        localStorage.setItem('lang','uz-latn')

      }
      if(localStorage.getItem('lang')=='uz-latn'){
        state.language=uz
        document.title=state.language.lang[0]
      }
      if(localStorage.getItem('lang')=='ru'){
        state.language=ru
        document.title=state.language.lang[0]
      }

      if(localStorage.getItem('lang')=='uz-cyrl'){
        state.language=uz_latn
        document.title=state.language.lang[0]
      }
      if(localStorage.getItem('lang')=='en'){
        state.language=en
        document.title=state.language.lang[0]
      }
      // else {
      //   state.language=ru
      // }
    },
    slider({state}){

      axios.get('/api/slider',{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
      .then(res=>{
        state.slider=res.data
      })
    },
    newsMain({state}){
      axios.get('/api/news?page_size=10',{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            state.news=res.data
          })
    },
    newsM({state},cre){
      axios.get('/api/news?page_size=12&&page='+cre,{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            state.newsM=res.data
          })
    },
    heritage({state}){
      axios.get('/api/heritage-category',{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            state.heritage_category=res.data
          })
    },
    newsMfiltr({state},credentials){
      axios.get('/api/news/?year='+credentials.year+"&&?month="+credentials.month,{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            state.newsM=res.data
          })
    },
    newsMsingl({state}){
      axios.get('/api/news/'+router.currentRoute.params.id,{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
      .then(res=>{
        state.newsMsingl=res.data
      })
    },

    museumMain({state}){
      axios.get('/api/museum?search=1&&page_size=5',{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            state.museum=res.data
          })
    },

    object({state}){
      axios.get('/api/object/',{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            state.object=res.data
          })
    },

    objects1({state}){
      axios.get('/api/object/',{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            state.object1=res.data
          })
    },

    stat({state}){
      axios.get('/api/stats',{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            state.stat=res.data
          })
    },

    management({state}){
      axios.get('/api/mgmt/1/?page_size=100',{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
      .then(res=>{
        state.management=res.data
      })
    },


    management2({state}){
      axios.get('/api/mgmt/2/',{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            state.management1=res.data
          })
    },

    management3({state}){
      axios.get('/api/mgmt/5/?page_size=100',{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            state.management3=res.data
            state.mn=res.data.managements[0]
          })
    },

    structure({state}){
      axios.get('/api/structure',{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            state.structure=res.data
          })
    },

    territorial({state}){
      axios.get('/api/territorial?page_size=20',{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            state.territorial=res.data
            state.tre=res.data.results[0]
            state.path=res.data.results[0].region
          })
    },


    organization({state},cre){
      axios.get('/api/organization?page='+cre,{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            state.organization=res.data
          })
    },

    docs1({state},creditails){
      axios.get('/api/docs/?id=6&'+creditails.name,{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
           state.docs4=res.data
          })
    },

    docs({state},credetials){
      axios.get('/api/document-category/6?page='+credetials.pagecounts,{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            state.docs1=res.data
          })
    },

    docsn({state},creditails){
      axios.get('/api/docs/?id=7&'+creditails.name,{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            state.docsn=res.data
          })
    },

    docsn1({state},credetials){
      axios.get('/api/document-category/7?page='+credetials.pagecounts,{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            state.docsn1=res.data
          })
    },
    docsnexpert({state},credetials){
      axios.get('/api/document-category/4?page_size=999&page='+credetials.pagecounts,{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            state.docx=res.data
          })
    },

    docsmain({state},credetials){
      axios.get('/api/document-category/14?page='+credetials.pagecounts,{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            state.docxmain=res.data
          })
    },


    docs3({state}){
      axios.get('/api/document-category/13',{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            state.docs3=res.data
          })
    },

    docs4({state}){
      axios.get('/api/document-category/12?page_size=100',{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            state.docs4=res.data
          })
    },
    docs15({state}){
      axios.get('/api/document-category/15',{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            state.docs15=res.data
          })
    },

    docs16({state}){
      axios.get('/api/document-category/16',{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            state.docs16=res.data
          })
    },

    docs17({state}){
      axios.get('/api/document-category/17',{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            state.docs17=res.data
          })
    },

    docs18({state}){
      axios.get('/api/document-category/18',{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            state.docs18=res.data
          })
    },

    docs19({state}){
      axios.get('/api/document-category/19',{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            state.docs19=res.data
          })
    },

    docs20({state}){
      axios.get('/api/document-category/20',{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            state.docs20=res.data
          })
    },
    docs21({state}){
      axios.get('/api/document-category/21',{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            state.docs21=res.data
          })
    },
    docs22({state}){
      axios.get('/api/document-category/22',{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            state.docs22=res.data
          })
    },


    docs23({state}){
      axios.get('/api/document-category/23',{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            state.docs23=res.data
          })
    },
    docs24({state}){
      axios.get('/api/document-category/24',{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            state.docs24=res.data
          })
    },

    docs27({state}){
      axios.get('/api/document-category/27',{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            state.docs24=res.data
          })
    },





    museumMore({state},cre){
      axios.get('/api/museum?page='+cre,{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            state.museumm=res.data

          })
    },
    managment_category({state}){
      axios.get('/api/mgmt/4/?page_size=100',{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            state.managment_category=res.data
          })
    },
    council_advice({state}){
      axios.get('/api/council-advice',{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        },
        params:{
          page_size:999
        }
      })
          .then(res=>{
            state.council_advice=res.data
          })
    },
    vacancy({state},cre){
      axios.get('/api/vacancy?page='+cre,{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            state.vacancy=res.data
          })
    },

    about({state}){
      axios.get('/api/about',{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            state.about=res.data
          })
    },

    pressS({state}){
      axios.get('/api/press-secretary',{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            state.press_secretary=res.data
          })
    },

    media({state}){
      axios.get('/api/files',{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            state.media=res.data
            state.media2=res.data.results[0]
            state.media.results.forEach(el=>{
              state.images.push(el.file)
            })
          })
    },

    services({state}){
      axios.get('/api/service',{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            state.services=res.data
          })
    },
    museumId({state}){
      axios.get('/api/museum/'+router.currentRoute.params.id,{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            state.museumId=res.data
          })
    },

    opendata({state},cre){
      axios.get('/api/open-data?page='+cre,{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            state.opendata=res.data
          })
    },
    cor({state},cre){
      axios.get('/api/document-category/25?page='+cre,{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            state.opendata=res.data
          })
    },
    bujet({state},cre){
      axios.get('/api/document-category/27?page='+cre,{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            state.opendata=res.data
          })
    },
    bujetid({state}){
      axios.get('/api/files-document/'+router.currentRoute.params.id,{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            state.opendata=res.data
          })
    },
    opendataid({state}){
      axios.get('/api/open-data/'+router.currentRoute.params.id,{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            state.opendataid=res.data
          })
    },
    transparency({state}){
      axios.get('https://opendata.bostonliqlive.uz/api/transparency-budget/admin',{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            let response = []
            let lang = localStorage.getItem('lang')
            if(lang == 'ru'){
              response = []
              res.data && res.data.forEach(e=>{
                if(e.ru) response.push(e)
              })
            }
            else if(lang == 'en'){
              response = []
              res.data && res.data.forEach(e=>{
                if(e.en) response.push(e)
              })
            }
            else if(lang == 'uz-cyrl'){
              response = []
              res.data && res.data.forEach(e=>{
                if(e.uz) response.push(e)
              })
            }
            else{
              response = []
              res.data && res.data.forEach(e=>{
                if(e.uzLatin) response.push(e)
              })
            }
            state.transparency=response
          })
    },

    downlaod(_,payload) {
      const apiUrl = 'https://'+payload.link
      // let link = {...payload}
      return axios({
          method: "GET",
          // data: link,
          url: apiUrl,
          responseType: 'blob'
      }).then(response => {
          var file = (new Blob([response.data]))
          var fileURL = window.URL.createObjectURL(new Blob([file]))
          var fileLink = document.createElement("a")
          fileLink.href = fileURL
          var fileType = payload.link && payload.link.split(".")[payload.link.split(".").length-1]
          var fileName = payload.link && payload.link.split(".")[payload.link.split(".").length-2]
          fileLink.setAttribute("download", `${fileName}.${fileType}`)
          document.body.appendChild(fileLink)

          fileLink.click()
          return "fileUploaded"
        }).catch((err)=>{
          console.log(err);
          return err
      })
  },
  downlaod2(_,payload) {
    const apiUrl = payload.link
    // let link = {...payload}
    return axios({
        method: "GET",
        // data: link,
        url: apiUrl,
        responseType: 'blob'
    }).then(response => {
        var file = (new Blob([response.data]))
        var fileURL = window.URL.createObjectURL(new Blob([file]))
        var fileLink = document.createElement("a")
        fileLink.href = fileURL
        var fileType = payload.link && payload.link.split(".")[payload.link.split(".").length-1]
        var fileName = payload.link && payload.link.split(".")[payload.link.split(".").length-2]
        fileLink.setAttribute("download", `${fileName}.${fileType}`)
        document.body.appendChild(fileLink)

        fileLink.click()
        return "fileUploaded"
      }).catch((err)=>{
        console.log(err);
        return err
    })
},
    transparencyid({state}){
      axios.get('https://opendata.bostonliqlive.uz/api/transparency-budget/'+router.currentRoute.params.id,{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            state.transparencyid=res.data
          })
    },

    search({state},creditalies){
      axios.get('/search/?name='+creditalies,{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            state.searchs=res.data
          })
    },
    search_id({state},creditalies){
      axios.get('/search/'+creditalies,{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            state.searchs_id=res.data
          })
    },

    heritages_main({state},cre){
      axios.get('/api/heritage/',{cre},{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            state.heritage_main=res.data
          })
    },

    museum_main({state},cre){
      axios.get('/api/museum?page='+cre,{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            state.museumm=res.data
          })
    },
    heritages_main1({state},cre){
      axios.get('/api/heritage?page='+cre+'&&unesco=1',{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            state.heritage_main=res.data
          })
    },
    heritages_main_searchs({state},cre){
      axios.get('/api/heritage?name='+cre.name+'&type='+cre.cat_id+'&region='+cre.reg_id+'&search='+cre.search+'&page='+cre.page,{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            state.heritage_main=res.data
          })
    },
    museum_main_searchs({state},cre){
      axios.get('/api/museum?search='+cre.search+'&page='+cre.page,{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            state.museumm=res.data
          })
    },

    heritages_res({state},cre){
      axios.get('/api/heritage?condition='+cre,{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            state.heritage_main=res.data
          })
    },
    heritages_id({state}){
      axios.get('/api/heritage/'+router.currentRoute.params.id+'/',{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            state.heritage_id=res.data
          })
    },
    contactFunck({state}){
      axios.get('/api/contact/',{
        headers:{
          'Accept-Language':localStorage.getItem('lang')
        }
      })
          .then(res=>{
            state.contact=res.data
          })
    },
    journal({state},cre){
      axios.get('/api/journal?page='+cre,{
        headers:{
          'Accept-Language':'uz-latn'
        }
      })
          .then(res=>{
            state.journal=res.data
          })
    },
  },
  modules: {

  }
})
