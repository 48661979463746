import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/agency/management',
    name: 'Management',
    component: () => import('../components/Agency/Management')
  },
  {
    path: '/agency/management',
    name: 'Management',
    component: () => import('../components/Agency/Management')
  },
  {
    path: '/central/office',
    name: 'central/office',
    component: () => import('../components/Agency/Central')
  },
  {
    path: '/corruption',
    name: '/corruption',
    component: () => import('../views/Corruption')
  },
  {
    path: '/structure',
    name: 'structure',
    component: () => import('../components/Agency/Structure')
  },
  {
    path: '/council',
    name: 'structure',
    component: () => import('../components/Agency/Council')
  },
  {
    path: '/territorial',
    name: 'territorial',
    component: () => import('../components/Agency/Territorial')
  },
  {
    path: '/resolutions',
    name: 'Resolutions',
    component: () => import('../components/Agency/Decrees')
  },

  {
    path: '/regulations',
    name: 'Regulations',
    component: () => import('../components/Agency/Regulations')
  },
  {
    path: '/organization',
    name: 'Organization',
    component: () => import('../components/Agency/Organization')
  },
  {
    path: '/museum',
    name: 'Museum',
    component: () => import('../components/Agency/Activity/')
  },
  {
    path: '/activity',
    name: 'Activity',
    component: () => import('../components/Agency/Activity')
  },
  {
    path: '/vacancy',
    name: 'Activity',
    component: () => import('../components/Agency/Vacancy')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('../components/News/News')
  },
  {
    path: '/news/:id',
    name: 'news',
    component: () => import('../components/News/Newssingil')
  },
  {
    path: '/media',
    name: 'news',
    component: () => import('../components/News/Medialibrary')
  },
  {
    path: '/press_secretary',
    name: 'news',
    component: () => import('../components/News/Press_secretary')
  },
  {
    path: '/press_releases',
    name: 'news',
    component: () => import('../components/News/PressReleases')
  },
  {
    path: '/tenders',
    name: 'news',
    component: () => import('../components/News/Tenders')
  },
  {
    path: '/contact',
    name: 'news',
    component: () => import('../components/Contact/Contact')
  },
  {
    path: '/connections',
    name: 'news',
    component: () => import('../components/Connections/index')
  },
  {
    path: '/maps_site',
    name: 'news',
    component: () => import('../components/MapSite/index')
  },
  {
    path: '/service',
    name: 'news',
    component: () => import('../views/Services')
  },
  {
    path: '/opendata',
    name: 'news',
    component: () => import('../views/OpenData')
  },
  {
    path: '/projects',
    name: 'news',
    component: () => import('../views/Projects')
  },
  {
    path: '/transparency-budget',
    name: 'announcement',
    component: () => import('../views/TransparencyBudget.vue')
  },
  {
    path: '/opendata/:id',
    name: 'news',
    component: () => import('../views/OpenDataSingle')
  },
  {
    path: '/transparency-budget/:id',
    name: 'news',
    component: () => import('../views/TransparencyBudgetSingle')
  },
  {
    path: '/museum/:id',
    name: 'news',
    component: () => import('../components/Agency/Activity/musium')
  },
  {
    path: '/object',
    name: 'news',
    component: () => import('../views/Object')
  },
  {
    path: '/object/:id',
    name: 'news',
    component: () => import('../views/ObjectSingle')
  },


  {
    path: '/unesco',
    name: 'news',
    component: () => import('../views/Unesco/Table')
  },


  {
    path: '/monitoring',
    name: 'news',
    component: () => import('../views/Unesco/Monitoring')
  },


  {
    path: '/report',
    name: 'news',
    component: () => import('../views/Unesco/Report')
  },


  {
    path: '/decision',
    name: 'news',
    component: () => import('../views/Unesco/Decisions')
  },

  {
    path: '/unesco_report',
    name: 'news',
    component: () => import('../views/Unesco/Unesco_report')
  },
  {
    path: '/global_search',
    name: 'news',
    component: () => import('../views/GlobalSearch')
  },


  {
    path: '/complain',
    name: 'news',
    component: () => import('../views/Unesco/Complain')
  },

  {
    path: '/paper',
    name: 'news',
    component: () => import('../views/Jurnal')
  },
  {
    path: '*',
    name: 'news',
    component: () => import('../views/404')
  },



]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        selector: to.hash
      };
    } else {
      return { x: 0, y: 0 };
    }
  }
});

export default router
